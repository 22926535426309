import { getRetailLocation } from '../../../services/api/retailLocationAPI';
import { formatDate } from '../../../utils/utils';
import { parsePromotionValue } from '../helpers';
import RetailLocationModal, { Action } from '../retail_location_modal/RetailLocationModal';
import SyncRetailLocationInventoryModal from '../retail_location_sync_inventory_modal/SyncRetailLocationInventoryModal';
import { EditOutlined, ApiOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Spin, Empty, Descriptions, Button, Row, Col, Card, Statistic } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './retail_location_detail.less';

type Props = {
  match: {
    params: {
      id: string;
    };
  };
};
export interface PromotionsDictionary {
  freeShipping: string;
  freeDelivery: string;
}

export const promotionsDictionary: PromotionsDictionary = {
  freeShipping: 'Free Shipping',
  freeDelivery: 'Free Delivery',
};

const RetailLocationDetail = (props: Props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [openEditRetailLocationModal, setOpenEditRetailLocationModal] = useState(false);
  const [syncStoreInventoryModal, setSyncStoreInventoryModal] = useState(false);

  const { isLoading, error, data } = useQuery({
    queryKey: ['getRetailLocation', id],
    queryFn: () => getRetailLocation(id),
  });

  if (isLoading) return <Spin size="large" />;
  if (error || !data) return <Empty />;

  const {
    name,
    isOnlineStore,
    pickup,
    delivery,
    mobileEnabled,
    mobWebEnabled,
    phoneNumber,
    isMedicalOnly,
    bccEmail,
    website,
    city,
    state,
    zipcode,
    address,
    totalProducts,
    pos,
    retailOrganization,
    geoLocation,
    promotions,
    pickupHours,
    deliveryHours,
    logoUrl,
    bannerUrl,
    slug,
    preOrderAdvanceDays,
    defaultDeliveryWindow,
    eligibleZipCodes,
    deliveryWindow,
    isPhotoIdRequired,
    paymentMethods,
    pickupPaymentMethods,
    daysOff,
    photoIdInstruction,
    pricesIncludeTaxes,
    googlePlaceId,
  } = data || {};

  const userDetails = JSON.parse(localStorage.getItem('userDetails') as string);
  const isAdmin = userDetails?.role.includes('admin_user');

  return (
    <div className="wrapper">
      <Descriptions title={name} bordered style={{ marginTop: 20 }}>
        <Descriptions.Item label="ID">{data?.id}</Descriptions.Item>
        <Descriptions.Item label="Mobile App (iOS)">
          {mobileEnabled ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Web Browser (Android)">
          {mobWebEnabled ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Pickup">{pickup ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Delivery">{delivery ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Medical Only">{isMedicalOnly ? 'Yes' : 'No'}</Descriptions.Item>
        <Descriptions.Item label="Products">{totalProducts}</Descriptions.Item>
        <Descriptions.Item label="Prices include taxes">
          {pricesIncludeTaxes ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number">{phoneNumber}</Descriptions.Item>
        <Descriptions.Item label="BCC Email">{bccEmail}</Descriptions.Item>
        <Descriptions.Item label="Website" span={2}>
          {website}
        </Descriptions.Item>
        <Descriptions.Item label="Address">{address}</Descriptions.Item>
        <Descriptions.Item label="City">{city}</Descriptions.Item>
        <Descriptions.Item label="State">{state}</Descriptions.Item>
        <Descriptions.Item label="Zipcode">{zipcode}</Descriptions.Item>
        <Descriptions.Item label="POS name">{pos?.name}</Descriptions.Item>
        <Descriptions.Item label="type">{pos?.type}</Descriptions.Item>
        <Descriptions.Item label="Minimum stock">{pos?.stockMinimum}</Descriptions.Item>
        {pos?.connectionInfo?.shopifyDomain && (
          <Descriptions.Item label="Shopping Domain">
            {pos?.connectionInfo?.shopifyDomain}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Organization">
          <Link to={'../organization/' + retailOrganization?.id}>
            {retailOrganization?.name || retailOrganization?.id}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Geo Location">{JSON.stringify(geoLocation)}</Descriptions.Item>
        <Descriptions.Item label="slug">{slug}</Descriptions.Item>
      </Descriptions>
      <br />
      <div>Location inventory</div>
      <Row gutter={16}>
        <Col span={4} style={{ marginBottom: 15 }}>
          <Card title="Automatic inventory synchronizations">
            <Statistic
              value={pos.disabled ? 'Disabled' : 'Enabled'}
              valueStyle={{
                color: '#5344ff',
              }}
            />
          </Card>
        </Col>
        <Col span={4} style={{ marginBottom: 15 }}>
          <Card title="Last synchronization results">
            {pos.lastSyncAttempt && (
              <>
                <h3>
                  <span style={{ color: '#5344ff', fontWeight: 'bold' }}>Run at:</span>{' '}
                  {formatDate(new Date(pos?.lastSyncAttempt?.timestamp))}
                </h3>
                <h3>
                  <span style={{ color: '#5344ff', fontWeight: 'bold' }}>Status:</span>{' '}
                  {pos?.lastSyncAttempt?.status}
                </h3>
                {pos?.lastSyncAttempt?.status === 'failed' && (
                  <h3>
                    <span style={{ color: '#5344ff', fontWeight: 'bold' }}>Message:</span>{' '}
                    {pos?.lastSyncAttempt?.message}
                  </h3>
                )}
              </>
            )}
          </Card>
        </Col>
        <Col span={4} style={{ marginBottom: 15 }}>
          <Card title="Minimum item stock">
            <Statistic
              value={pos?.stockMinimum ?? 'Not defined'}
              valueStyle={{
                color: '#5344ff',
              }}
            />
          </Card>
        </Col>
      </Row>
      {!!promotions && !!promotions.length && (
        <>
          <div>Promotions</div>
          <Row gutter={16}>
            {promotions.map((item, index: number) => (
              <Col key={index} span={4} style={{ marginBottom: 15 }}>
                <Card
                  title={promotionsDictionary[item.type as keyof PromotionsDictionary] || item.type}
                >
                  <Statistic
                    value={parsePromotionValue(item)}
                    valueStyle={{
                      color: '#5344ff',
                    }}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      <br />
      <div
        style={{
          display: 'flex',
          gap: '2rem',
        }}
      >
        <Button
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setOpenEditRetailLocationModal(true)}
        >
          Edit
        </Button>
        {isAdmin && (
          <Button
            type="primary"
            icon={<ApiOutlined />}
            onClick={() => setSyncStoreInventoryModal(true)}
          >
            Synchronize inventory
          </Button>
        )}
      </div>

      {openEditRetailLocationModal && (
        <RetailLocationModal
          visible={true}
          onRequestClose={() => setOpenEditRetailLocationModal(false)}
          editObject={{
            id: data.id,
            name,
            phoneNumber,
            address: address ?? '',
            website,
            city: city ?? '',
            state: state ?? '',
            zipcode: zipcode ?? '',
            isOnlineStore,
            pickup,
            delivery,
            mobileEnabled,
            mobWebEnabled,
            isMedicalOnly,
            bccEmail,
            organization: {
              id: retailOrganization?.id,
              name: retailOrganization?.name,
            },
            latitude: geoLocation?.coordinates ? geoLocation.coordinates[1] : '',
            longitude: geoLocation?.coordinates ? geoLocation.coordinates[0] : '',
            menus: pos?.menus,
            posName: pos?.name,
            posType: pos?.type,
            customerSearchType: pos?.customerSearchType,
            customerSearchBy: pos?.customerSearchBy,
            posStockMinimum: pos?.stockMinimum,
            posDisabled: pos.disabled,
            connectionInfo: pos?.connectionInfo,
            promotions,
            pickupHours,
            deliveryHours,
            logoUrl,
            bannerUrl,
            preOrderAdvanceDays,
            defaultDeliveryWindow,
            eligibleZipCodes,
            deliveryWindow,
            isPhotoIdRequired,
            paymentMethods,
            pickupPaymentMethods,
            daysOff,
            photoIdInstruction,
            pricesIncludeTaxes,
            googlePlaceId,
          }}
          action={Action.edit}
        />
      )}

      <SyncRetailLocationInventoryModal
        visible={syncStoreInventoryModal}
        onRequestClose={() => setSyncStoreInventoryModal(false)}
        data={{
          id: data.id,
          pos: pos,
        }}
      />
    </div>
  );
};

export default RetailLocationDetail;
